export interface IAuthState {
  isAuthenticated: boolean
  token: string
  menu: string[]
  username: string
  curUserObject: any
}

export const AuthDefaultState = (): IAuthState => {
  return {
    isAuthenticated: false,
    token: '',
    menu: [],
    username: '',
    curUserObject: null
  }
}
