
  import Vue from 'vue'

  import { mapActions, mapGetters } from 'vuex'

  import { defineComponent, onMounted, PropType, ref } from 'vue'

  /* Models */
  import { IApiRequestModel } from '@/core/data/apiRequestModel'

  /* Actions */
  import { alertPrompt, confirmPrompt, ISwalModel } from '@/core/helpers/app'

  /* Services */
  import WMSProcessApiService from '@/core/services/modules/WMSProcessApiService'

  export default Vue.extend({
    name: 'subirarchivo',
    props: {
      modulo: {
        type: String,
        default: () => ''
      } as any,
      hrefitem: {
        type: Object,
        default: () => {
          return {
            url: '',
            label: ''
          }
        }
      } as any,
      document: {
        type: Object,
        default: () => {
          return {
            docnumber: '',
            uploadFiletype: ''
          }
        }
      }
    },

    setup() {
      const myinputid = ref<HTMLElement | null>(null)
      onMounted(() => {
        myinputid.value?.focus()
      })

      const focusInput = (value: any) => {
        if (value === true) myinputid.value?.focus()
      }

      return {
        myinputid,
        focusInput
      }
    },

    data() {
      return {
        sendfile: false as boolean,
        filed: null as any,
        itemSubir: {} as object,
        loader: true as boolean,
        uploaded: false as boolean,
        info_extra: {
          text: '',
          ok: {
            headers: [],
            rows: []
          },
          error: {
            headers: [],
            rows: []
          }
        } as any,
        tab: null
      }
    },
    mounted() {
      //console.log()
    },

    methods: {
      ...mapActions(['notification', 'loading_show', 'loading_hide']),

      async up() {
        if (this.filed == null) return false

        this.$store.dispatch('app/showLoading')

        this.uploaded = false

        let base64 = await this.getBase64(this.filed).then((data: any) => {
          return data
        })

        this.sendfile = true

        let file = {
          nombre: this.filed.name,
          base64: base64,
          size: this.filed.size
        }

        const siteSelected = window.localStorage.getItem('SITE_SELECTED') as string

        const request: IApiRequestModel = {
          process: 'UPLOADFILE',
          action: 'UPLOAD_FILE',
          site: siteSelected,
          document: this.modulo,
          data_captured: JSON.stringify(file),
          parameters: { docnumber: this.document.docnumber, type: this.document.uploadFiletype }
        } as IApiRequestModel

        const response = (await WMSProcessApiService.post(request)) as any

        this.$store.dispatch('app/hideLoading')

        if (!response?.isAxiosError) {
          console.log(response)
          alertPrompt({
            text: response.data.message + response.data.detail,
            confirmButtonText: this.$t('ok')
          } as ISwalModel).then(() => {
            //location.reload()
          })
        }

        this.uploaded = true

        this.info_extra.text = ''

        this.infoExtra(response.data)

        this.$store.dispatch('app/hideLoading')
      },
      downloadItem() {
        this.$http({
          method: 'get',
          url: this.hrefitem.url,
          responseType: 'arraybuffer'
        })
          .then((response) => {
            this.forceFileDownload(response)
          })
          .catch(() => console.log('error occured'))
      },
      forceFileDownload(response: any) {
        var date = new Date()

        var extraText = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds()

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'PL_' + this.modulo + ' ' + extraText + '.xlsx') //or any other extension
        document.body.appendChild(link)
        link.click()
      },

      infoExtra(data: any) {
        try {
          var headers_ok = Object.keys(data.table[0])

          headers_ok.forEach((e) => {
            var obj_ok = {
              text: e,
              value: e
            }

            this.info_extra.ok.headers.push(obj_ok)
          })

          this.info_extra.ok.rows = data.table
        } catch {
          console.log('no hay infoextra')
        }

        try {
          var headers_error = Object.keys(data.table1[0])

          headers_error.forEach((e) => {
            var obj_error = {
              text: e,
              value: e
            }

            this.info_extra.error.headers.push(obj_error)
          })

          this.info_extra.error.rows = data.table1
        } catch {
          console.log('no hay infoextra')
        }
      },
      getBase64(file: any) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })
      }
    }
  })
