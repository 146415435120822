
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'

  /**  Service **/

  /**  Views **/

  export default Vue.extend({
    name: 'LayoutApp',

    data: () => ({}),

    computed: {
      ...mapGetters({ loading: 'app/loading', help: 'app/help' })
    },

    methods: {
      ...mapActions({ toggleHelp: 'app/toggleHelp' })
    }
  })
