import store from '@/store'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig, RouteRecord } from 'vue-router'

// Routers
import inquiryRouter from './modules/inquiry'
import masterRouter from './modules/master'
import appRouter from './modules/app'

// Components
import Layout from '@/components/Layout/Layout.vue'
import LayoutAuth from '@/components/Layout/LayoutAuth.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dashboard',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/inventory/count/documents',
        name: 'documents-inventory-count-list',
        component: () => import('@/views/documents/inventory/count-task/CounTaskList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/inventory/count/detail/:id',
        name: 'documents-inventory-count-detail',
        component: () => import('@/views/documents/inventory/count-task/CounTaskDocument.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/documents',
        name: 'documents-shipping-list',
        component: () => import('@/views/documents/shipping/ShippingList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/detail/:id',
        name: 'documents-shipping-detail',
        component: () => import('@/views/documents/shipping/ShippingDocument.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/comprometer-inventario',
        name: 'documents-shipping-comprometer-inventario',
        component: () => import('@/views/documents/shipping/ShippingComprometerInventario.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/packing/:id',
        name: 'documents-shipping-packing',
        component: () => import('@/views/documents/shipping/ShippingPackingList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ' /process/shipping/tracking/:id?',
        name: 'documents-shipping-tracking',
        component: () => import('@/views/documents/shipping/ShippingTracking.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/delivery-local/:id?',
        name: 'documents-shipping-delivery-local',
        component: () => import('@/views/app/components/shipping/DeliveryLocalTask.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/delivery-local-confirm',
        name: 'documents-shipping-delivery-local-confirm',
        component: () => import('@/views/documents/shipping/ShippingDeliveryLocalConfirm.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/delivery-pending/:id?',
        name: 'documents-shipping-delivery-pending',
        component: () => import('@/views/documents/shipping/ShippingDeliveryPending.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/inventory/bin-change',
        name: 'bin-change',
        component: () => import('@/views/documents/inventory/BinChangeList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/inventory/bin-change-erp/:tab?',
        name: 'inquiry-bin-change-erp',
        component: () => import('@/views/documents/inventory/BinChangeERPList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/reports/report_base/:id?',
        name: 'report_base',
        component: () => import('@/views/reports/reportBase.vue'),
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: '/master/user',
      //   name: 'm-user',
      //   component: () => import('@/views/setup/master/SUsuario.vue')
      // },
      {
        path: '/process/receiving/documents',
        name: 'documents-receiving-list',
        component: () => import('@/views/documents/receiving/ReceivingList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/receiving/detail/:id',
        name: 'documents-receiving-detail',
        component: () => import('@/views/documents/receiving/ReceivingDocument.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/delivery-confirm',
        name: 'documents-shipping-delivery-confirm',
        component: () => import('@/views/documents/shipping/ShippingDeliveryConfirm.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/shipping/delivery-confirm',
        name: 'documents-shipping-delivery-confirm',
        component: () => import('@/views/documents/shipping/ShippingDeliveryConfirm.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/integration/control',
        name: 'integration-control',
        component: () => import('@/views/process/integration/Control.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/inventory/reclassification',
        name: 'reclassification',
        component: () => import('@/views/documents/inventory/Reclassification.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/inventory/counting-documents',
        name: 'counting-documents',
        component: () => import('@/views/documents/inventory/CountingList.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/process/inventory/counting-detail/:id',
        name: 'counting-detail',
        component: () => import('@/views/documents/inventory/CountingDocument.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/',
    component: LayoutAuth,
    children: [
      {
        path: 'sign-in',
        name: 'sign-in',
        component: () => import('@/views/authentication/SignIn.vue'),
        meta: {
          requiresAuth: false
        }
      },
      // {
      //   path: 'sign-up',
      //   name: 'sign-up',
      //   component: () => import('@/views/authentication/SignUp.vue'),
      //   meta: {
      //     requiresAuth: false
      //   }
      // },
      // {
      //   path: 'password-reset',
      //   name: 'password-reset',
      //   component: () => import('@/views/authentication/PasswordReset.vue'),
      //   meta: {
      //     requiresAuth: false
      //   }
      // },
      {
        path: 'register-user/:process?/:token?',
        name: 'register-user',
        component: () => import('@/views/authentication/RegisterUser.vue'),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  appRouter,
  inquiryRouter,
  masterRouter,
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/Error404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  store.dispatch('auth/verifyAuth')

  if (to.matched.some((record: RouteRecord) => record.meta.requiresAuth)) {
    const isAuthenticated = store.getters['auth/isAuthenticated']
    if (!isAuthenticated) {
      if (to.fullPath !== '/') {
        next({ path: '/sign-in', query: { redirect: to.fullPath } })
      } else {
        next({ path: '/sign-in' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
