import { ActionContext } from 'vuex'
import { IAuthState } from './state'
import { IRootState } from '@/core/data/IRootState'
import { IUserModel } from '@/core/data/authModel'
import router from '@/router'
import store from '@/store'

import WMSAuthApiService from '@/core/services/modules/WMSAuthApiService'
import JwtService from '@/core/services/JwtService'
import WMSApiClientService from '@/core/services/WMSApiClientService'

export interface IAuthActions {
  login(context: ActionContext<IAuthState, IRootState>, data: IUserModel): void
  logout(context: ActionContext<IAuthState, IRootState>, notReload: boolean): void
  authApp(context: ActionContext<IAuthState, IRootState>, token: string): void
  verifyAuth(context: ActionContext<IAuthState, IRootState>): void
  purgeAuth(context: ActionContext<IAuthState, IRootState>): void
}

export const AuthActions: IAuthActions = {
  async login({ commit }: ActionContext<IAuthState, IRootState>, { email, password }: IUserModel) {
    const response = await WMSAuthApiService.signIn({ email, password } as IUserModel)

    if (!response.isAxiosError) {
      const user = { ...response.data.user[0], menu: response.data.menu, setup: response.data.setup, sites: response.data.sites } as IUserModel

      JwtService.saveToken(response.data.api_token)
      commit('CHANGE_IS_AUTHENTICATED', true)
      commit('SET_TOKEN', response.data.api_token)
      commit('SET_MENU', response.data.menu)
      commit('SET_USERNAME', user.email)
      commit('SET_CURUSER_OBJECT', user)

      //  WMSApiClientService.setHeader()

      try {
        window.localStorage.setItem('SITE_SELECTED', user.sites[0].site_id)
      } catch {
        console.log('Usuario sin bodega asignada')
      }

      try {
        let lang = 'es'

        if (user.setup[0].name == 'idioma') lang = user.setup[0].value

        window.localStorage.setItem('LANGUAJE', lang)
      } catch {
        console.log('Usuario sin bodega asignada')
      }

      router.push('/dashboard').catch(() => {
        console.log('')
      })
    }
  },

  async logout({ commit }: ActionContext<IAuthState, IRootState>, notReload: boolean) {
    store.dispatch('auth/purgeAuth')

    router.push('/sign-in').catch(() => {
      console.log('')
    })

    if (!notReload) location.reload()
  },

  async authApp({ commit }: ActionContext<IAuthState, IRootState>, token: string) {
    JwtService.saveToken(token)
    commit('CHANGE_IS_AUTHENTICATED', true)
    commit('SET_TOKEN', token)

    WMSApiClientService.init()

    WMSApiClientService.setHeader()
  },

  async verifyAuth({ commit }: ActionContext<IAuthState, IRootState>) {
    if (JwtService.getToken()) {
      WMSApiClientService.setHeader()
    } else {
      store.dispatch('auth/purgeAuth')
    }
  },

  async purgeAuth({ commit }: ActionContext<IAuthState, IRootState>) {
    JwtService.destroyToken()
    commit('CHANGE_IS_AUTHENTICATED', false)
    commit('SET_TOKEN', null)
    commit('SET_MENU', [])
    commit('SET_USERNAME', null)
    commit('SET_CURUSER_OBJECT', null)
    WMSApiClientService.destroyToken()
  }
}
