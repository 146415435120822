import { IApiRequestModel } from '@/core/data/apiRequestModel'
import WMSApiClientService from '../WMSApiClientService'

class WMSProcessApiService {
  public static async get(data: IApiRequestModel) {
    const siteSelected = window.localStorage.getItem('SITE_SELECTED') as string

    let dataSite = false

    if (data.process == 'INVENTORY' && data.action == 'BIN_CHANGE_LOG' && data.session_id != '') dataSite = true

    if (data.process == 'SHIPPING' && data.action == 'LIST' && data.session_id != '') dataSite = true

    if (siteSelected != null && siteSelected != undefined && siteSelected != '' && !dataSite) data.site = siteSelected

    return await WMSApiClientService.get(
      'Process',
      `?process=${data.process}&action=${data.action}&site=${data.site}&document=${data.document}&step=${data.step}&data_captured=${data.data_captured}&username=${data.username}&session_id=${data.session_id}&parameters=${data.parameters}&id_process=${data.id_process}`
    )
  }

  public static async post(data: IApiRequestModel) {
    return await WMSApiClientService.post('Process', data as any)
  }
}

export default WMSProcessApiService
