
  import Vue from 'vue'
  import { mapActions } from 'vuex'

  export default Vue.extend({
    props: ['help'],

    data() {
      return {}
    },

    methods: {
      ...mapActions({ toggleHelp: 'app/toggleHelp' })
    }
  })
