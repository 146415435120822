import { RouteConfig } from 'vue-router'
import LayoutApp from '@/components/Layout/LayoutApp.vue'

const appRouter: RouteConfig = {
  path: '/app',
  component: LayoutApp,
  children: [
    {
      path: 'process/:token?/:process?/:sessionid?/:tab?/:document?/:site?',
      name: 'ProcessApp',
      component: () => import('@/views/app/Process.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: 'search/:token?/:process?/:sessionid?',
      name: 'SearchApp',
      component: () => import('@/views/app/Search.vue'),
      meta: {
        requiresAuth: false
      }
    }
  ]
}

export default appRouter
