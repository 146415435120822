import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios, { AxiosError, AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import JwtService from '@/core/services/JwtService'

import { alertPrompt, ISwalModel } from '@/core/helpers/app'
import router from '@/router'

Vue.use(VueAxios, axios)

/**
 * @description service to call HTTP request via Axios
 */
class WMSApiClientService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: AxiosInstance

  /**
   * @description initialize vue axios
   */
  public static init() {
    const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL + '/api/' : process.env.VUE_APP_API_URL_LOCAL + '/api/'

    WMSApiClientService.vueInstance = axios.create({
      baseURL: baseURL,
      //baseURL: 'https://localhost:44326/api',
      //baseURL: 'https://api.wmsligero.com/impormedical/api',
      timeout: 2000000
    })

    WMSApiClientService.vueInstance.interceptors.response.use(
      (res: any) => {
        return res
      },
      (error: AxiosError) => {
        return WMSApiClientService.errorResponseHandler(error)
      }
    )
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    WMSApiClientService.vueInstance.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`
    WMSApiClientService.vueInstance.defaults.headers.common['Accept'] = 'application/json'
  }

  public static destroyToken(): void {
    WMSApiClientService.vueInstance.defaults.headers.common['Authorization'] = ''
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return WMSApiClientService.vueInstance.get(resource, params)
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string, slug = '' as string): Promise<AxiosResponse> {
    return WMSApiClientService.vueInstance.get(`${resource}/${slug}`)
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return WMSApiClientService.vueInstance.post(`${resource}`, params)
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return WMSApiClientService.vueInstance.put(`${resource}/${slug}`, params)
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return WMSApiClientService.vueInstance.put(`${resource}`, params)
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return WMSApiClientService.vueInstance.delete(resource)
  }

  public static errorResponseHandler(error: AxiosError) {
    let messageAlert = ''

    if (error.response && error.response.data) {
      const _error = error as any

      if (_error.response.data.errors) {
        try {
          messageAlert += _error.response.data.errors || ''
        } catch {
          //console.log(_error)
        }
      }

      if (_error.response.data['message']) {
        try {
          messageAlert += '\n' + _error.response.data.message || ''
        } catch {
          //console.log(_error)
        }
      }

      if (_error.response.data['detail']) {
        try {
          messageAlert += '\n' + _error.response.data.detail || ''
        } catch {
          //console.log(_error)
        }
      }
    } else {
      messageAlert = error.message
    }

    alertPrompt({
      text: messageAlert,
      type: 'error'
    } as ISwalModel)

    if (error.response && error.response.status === 401) {
      console.log('Not Authorization')
      router.push('/sign-in')
      location.reload()
    }

    return { ...error, isAxiosError: true }
  }
}

export default WMSApiClientService
