import * as Vue from 'vue'
import Swal from 'sweetalert2'

export interface ISwalModel {
  title: string
  text: string
  type: string
  confirmButtonText: string
  cancelButtonText: string
}

export const alertPrompt = (payload: ISwalModel): Promise<void> => {
  return new Promise<void>((resolve) => {
    Swal.fire({
      title: 'WMS | Ligero',
      text: payload?.text,
      icon: payload?.type || 'success',
      html: payload?.text,
      backdrop: 'false rgba(0,0,0,0.5)',
      allowOutsideClick: false,
      imageUrl: './logo.png',
      buttonsStyling: false,
      reverseButtons: true,
      confirmButtonText: payload.confirmButtonText || 'Ok',
      customClass: {
        confirmButton: 'btn font-weight-bold btn-primary',
        image: 'swal2-image-custom'
      }
    } as any)
      .then((result: any) => {
        if (result.value) {
          resolve()
        } else {
          resolve()
        }
      })
      .catch(() => resolve())
  })
}

export const confirmPrompt = (payload: ISwalModel): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    Swal.fire({
      imageUrl: './logo.png',
      imageAlt: 'WMS | Ligero',
      position: 'top',
      // title: payload?.title ? payload.title : 'WMS | Ligero',
      text: payload?.text,
      buttonsStyling: false,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: payload.confirmButtonText || 'Ok',
      cancelButtonText: payload.cancelButtonText || 'Cancel',
      customClass: {
        confirmButton: 'v-btn v-btn--is-elevated v-btn--fab v-btn--has-bg theme--light primary',
        cancelButton: 'v-btn v-btn--is-elevated v-btn--fab v-btn--has-bg theme--light danger',
        image: 'swal2-image-custom'
      }
    })
      .then((result: any) => {
        if (result.value) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
      .catch(() => resolve(false))
  })
}
