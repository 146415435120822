
  import Vue from 'vue'
  // import moment from 'moment'

  export default Vue.extend({
    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      label: String,
      keys: String,
      value: String,
      format: String,
      disabled: Boolean,
      rules: Array
    },

    data: () => ({
      show: false,
      timeFormatted: ''
    }),

    methods: {
      parse(): boolean {
        return this.value.indexOf('/') !== -1
      },

      change(event: string) {
        this.timeFormatted = event
        this.$emit('change', this.timeFormatted)
      }
    },

    computed: {
      valueField() {
        return this.value
      }
    },

    watch: {}
  })
