import { ActionContext } from 'vuex'
import { IAppState } from './state'
import { IRootState } from '@/core/data/IRootState'

export interface IAppActions {
  changeLocale(context: ActionContext<IAppState, IRootState>, locale: string): void

  setCookieConsentVersion(context: ActionContext<IAppState, IRootState>, version: string): void

  toggleSideBar(context: ActionContext<IAppState, IRootState>): void

  closeSideBar(context: ActionContext<IAppState, IRootState>): void

  showLoading(context: ActionContext<IAppState, IRootState>): void

  hideLoading(context: ActionContext<IAppState, IRootState>): void

  toggleHelp(context: ActionContext<IAppState, IRootState>, text: string): void
}

export const AppActions: IAppActions = {
  changeLocale: ({ commit }: ActionContext<IAppState, IRootState>, locale: string) => commit('CHANGE_LOCALE', locale),
  setCookieConsentVersion: ({ commit }: ActionContext<IAppState, IRootState>, version: string) => commit('SET_COOKIE_CONSENT_VERSION', version),
  toggleSideBar: ({ commit }: ActionContext<IAppState, IRootState>) => commit('TOGGLE_SIDEBAR'),
  closeSideBar: ({ commit }: ActionContext<IAppState, IRootState>) => commit('CLOSE_SIDEBAR'),
  showLoading: ({ commit }: ActionContext<IAppState, IRootState>) => commit('SHOW_LOADING'),
  hideLoading: ({ commit }: ActionContext<IAppState, IRootState>) => commit('HIDE_LOADING'),
  toggleHelp: ({ commit }: ActionContext<IAppState, IRootState>, data: any) => commit('TOGGLE_HELP', data)
}
