import { IAuthState } from './state'

export interface IAuthGetters {
  isAuthenticated(state: IAuthState): boolean
  menu(state: IAuthState): string[]
  username(state: IAuthState): string
  token(state: IAuthState): string
  curUserObject(state: IAuthState): any
}

export const AuthGetters: IAuthGetters = {
  isAuthenticated(state: IAuthState): boolean {
    return state.isAuthenticated
  },
  menu(state: IAuthState): string[] {
    return state.menu
  },
  username(state: IAuthState): string {
    return state.username
  },
  token(state: IAuthState): string {
    return state.token
  },
  curUserObject(state: IAuthState): any {
    return state.curUserObject
  }
}
