
  import Vue from 'vue'
  import moment from 'moment'

  export default Vue.extend({
    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      label: String,
      keys: String,
      value: String,
      format: String,
      disabled: Boolean,
      rules: Array
    },

    data: () => ({
      show: false,
      dateFormatted: ''
    }),

    methods: {
      parse(): boolean {
        return this.value.indexOf('/') !== -1
      },

      change(event: string) {
        this.dateFormatted = moment(event, 'YYYY-MM-DD').format(this.format || 'DD/MM/YYYY')
        this.$emit('change', this.dateFormatted)
        this.show = false
      },

      getFormat() {
        return this.format || 'DD/MM/YYYY'
      }
    },

    computed: {
      valueField() {
        return this.value
      }
    },

    watch: {}
  })
