
  import Vue from 'vue'

  export default Vue.extend({
    name: 'App',

    components: {},

    data: () => ({})

    // data: () => ({ connection: null as any }),

    // mounted() {
    //   window.addEventListener('message', this.listenMessage, false)

    //   // if (window.addEventListener) {
    //   //   window.addEventListener("message", listenMessage, false);
    //   // } else {
    //   //   window.attachEvent("onmessage", listenMessage);
    //   // }
    // },

    // methods: {
    //   listenMessage(event: any) {
    //     this.connection = JSON.stringify(event.origin) + JSON.stringify(event.data)
    //     // if (event.origin !== 'http://example.com:8080') return
    //     // var data = JSON.parse(event)
    //     // var action = data.action
    //     // var params = data.params
    //   }
    // }

    //   IsJsonString(str) {
    //     try {
    //         JSON.parse(str);
    //     } catch (e) {
    //         return false;
    //     }
    //     return true;
    // }
  })
