import { IAuthState } from './state'

export interface IAuthMutations {
  CHANGE_IS_AUTHENTICATED(state: IAuthState, auth: boolean): void
  SET_TOKEN(state: IAuthState, token: string): void
  SET_MENU(state: IAuthState, menu: string[]): void
  SET_USERNAME(state: IAuthState, username: string): void
  SET_CURUSER_OBJECT(state: IAuthState, curUserObject: any): void
}

export const AuthMutations: IAuthMutations = {
  CHANGE_IS_AUTHENTICATED: (state: IAuthState, auth: boolean) => {
    state.isAuthenticated = auth
  },
  SET_TOKEN: (state: IAuthState, token: string) => {
    state.token = token
  },
  SET_MENU: (state: IAuthState, menu: string[]) => {
    state.menu = menu
  },
  SET_USERNAME: (state: IAuthState, username: string) => {
    state.username = username
  },
  SET_CURUSER_OBJECT: (state: IAuthState, curUserObject: any) => {
    state.curUserObject = curUserObject
  }
}
