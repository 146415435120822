import { RouteConfig } from 'vue-router'
import Layout from '@/components/Layout/Layout.vue'

const inquiryRouter: RouteConfig = {
  path: '/inquiry',
  redirect: 'dashboard',
  component: Layout,
  children: [
    {
      path: 'inquirytool/:OPTION?',
      name: 'inquirytool',
      component: () => import('@/views/inquiry/Inquiry.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'inventory/bin-change',
      name: 'inquiry-bin-change',
      component: () => import('@/views/documents/inventory/BinChangeList.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
}

export default inquiryRouter
