
  import Vue from 'vue'

  export default Vue.extend({
    props: {
      width: {
        type: String,
        default: '50%'
      },

      fullscreen: {
        type: Boolean,
        default: false
      }
    }
  })
