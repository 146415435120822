/**
 * Global Components
 */
import Vue from 'vue'

// App Picker component
import AppDatePicker from '@/components/app-picker/AppDatePicker'
import AppTimePicker from '@/components/app-picker/AppTimePicker'

// App Dialog component
import AppDialog from '@/components/app-dialog/AppDialog'

// App Viwer component
import AppViewerPDF from '@/components/app-viewer/AppViewerPDF'

import AppHeader from '@/components/app-header/AppHeader'

import SubirArchivo from '@/components/SubirArchivo/subirarchivo'

import { VueSignaturePad } from 'vue-signature-pad'

Vue.component('VueSignaturePad', VueSignaturePad)
Vue.component('appDialog', AppDialog)
Vue.component('appDatePicker', AppDatePicker)
Vue.component('appTimePicker', AppTimePicker)
Vue.component('appHeader', AppHeader)
Vue.component('app-viewer-pdf', AppViewerPDF)
Vue.component('subir-archivo', SubirArchivo)
