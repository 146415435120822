
  /* Vue */
  import { defineComponent } from 'vue'

  /* Views */
  import pdf from 'vue-pdf'

  export default defineComponent({
    components: { pdf },

    props: {
      model: {
        type: Object,
        default: { url: null, arrayBuffer: null } as any
      },
      onClose: {
        type: Function,
        default: () => {
          return ''
        }
      }
    },

    computed: {
      myPdfComponent(): any {
        return this.$refs.myPdfComponent as any & { print: () => void }
      }
    },

    data() {
      return {
        dialog: true as boolean
      }
    },

    methods: {
      handleLoaded(instance: any) {
        console.log('PSPDFKit has loaded: ', instance)
        // Do something.
      },

      close() {
        if (this.onClose) this.onClose()
        else this.dialog = false
      }
    }
  })
