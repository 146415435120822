import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './i18n/en'
import es from './i18n/es'
import { mapGetters } from 'vuex'
import { IAuthGetters } from '../store/modules/auth/getters'

Vue.use(VueI18n)

const messages = {
  en: en.en,
  es: es.es
}

let locale_ = 'es'
let fallbackLocale_ = 'es'

locale_ = 'es'
fallbackLocale_ = 'es'

try {
  const lang = window.localStorage.getItem('LANGUAJE')

  if (lang && lang != '') locale_ = lang
} catch {
  console.log('Error asignado el idioma')
}

const i18n = new VueI18n({
  // legacy: false,
  locale: locale_,
  fallbackLocale: fallbackLocale_, // set fallback locale
  // globalInjection: true,
  messages
})

// const { t, mergeLocaleMessage } = i18n.global

// const changeLocale = (lang: string): void => {
//   i18n.global.locale = lang
// }

// export { t, mergeLocaleMessage, changeLocale }

export default i18n
