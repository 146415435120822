import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/src/locale/es'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#f8ca00',
        secondary: '#000000',
        accent: '#0F389A',
        danger: '#ff0000',
        error: '#ff0000',
        info: '#0d6efd',
        success: '#14b84e',
        warning: '#ffa800',
        gray: '#e6e6e6'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
} as any

export default new Vuetify(opts)
