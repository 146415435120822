import { IUserModel } from '@/core/data/authModel'
import WMSApiClientService from '../WMSApiClientService'

class WMSAuthApiService {
  public static async signIn(credentials: IUserModel): Promise<any> {
    return WMSApiClientService.post('Auth/SignIn', credentials as any)
  }
}

export default WMSAuthApiService
