import { RouteConfig } from 'vue-router'
import Layout from '@/components/Layout/Layout.vue'

const masterRouter: RouteConfig = {
  path: '/master',
  redirect: 'dashboard',
  component: Layout,
  children: [
    {
      path: 'bin',
      name: 'm-bin',
      component: () => import('@/views/setup/master/MBin.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'items-homologacion',
      name: 'm-items-homologacion',
      component: () => import('@/views/setup/master/cobomedical/MItemHomologacion.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'items-homologacion-terceros',
      name: 'm-items-homologacion-terceros',
      component: () => import('@/views/setup/master/cobomedical/MItemHomologacionTerceros.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'terceros-bodega',
      name: 'm-terceros-bodega',
      component: () => import('@/views/setup/master/cobomedical/MTercerosBodega.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'site',
      name: 'm-site',
      component: () => import('@/views/setup/master/MSite.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'company',
      name: 'm-company',
      component: () => import('@/views/setup/master/MCompany.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'delivery-company',
      name: 'm-delivery-company',
      component: () => import('@/views/setup/master/MDeliveryCompany.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'package',
      name: 'm-package',
      component: () => import('@/views/setup/master/MPackage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'user',
      name: 'm-user',
      component: () => import('@/views/setup/master/SUsuario.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'item',
      name: 'm-item',
      component: () => import('@/views/setup/master/MItem.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
}

export default masterRouter
