import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './globalComponents'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import WMSApiClientService from '@/core/services/WMSApiClientService'

import moment from 'moment' //moment-timezone
/* eslint-disable @typescript-eslint/no-var-requires */
const VueMoment = require('vue-moment')

import i18n from '@/plugins/i18n'

Vue.use(VueMoment, {
  moment
})
Vue.use(VueSweetalert2)

WMSApiClientService.init()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
