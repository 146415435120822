
  import Vue from 'vue'
  import { toJSON } from '@/assets/ts/_utils'
  import { mapGetters } from 'vuex'

  export default Vue.extend({
    name: 'Sidebar',

    data() {
      return {
        dialog: false,
        items: [] as any,
        version: ''
      }
    },

    computed: {
      ...mapGetters({ drawer: 'app/drawer', menu: 'auth/menu' })
    },

    mounted() {
      if (this.menu) {
        let menu = this.menu.map((row: any) => {
          return { ...row, sub: toJSON(row.sub) }
        })

        this.items = [
          {
            heading: 'process',
            route: '/process',
            pages: [...menu]
          }
        ]
      }

      this.version = process.env.VUE_APP_VERSION
    },

    methods: {
      resolvePath(basePath: string, routePath: string) {
        return basePath + routePath //path.resolve(basePath + routePath)
      },

      showOption(elementPath: string) {
        if (this.menu.find((f: any) => f.startsWith('/' + elementPath + '/'))) {
          return true
        } else {
          return false
        }
      },

      showOptionChildren(elementPath: string, childrenPath: string) {
        if (this.menu.find((f: any) => f.startsWith('/' + elementPath + childrenPath))) {
          return true
        } else {
          return false
        }
      }
    }
  })
