
  import Vue from 'vue'
  import { mapGetters } from 'vuex'

  /**  Service **/

  /**  Views **/

  export default Vue.extend({
    name: 'LayoutAuth',

    data: () => ({
      slides: [
        'https://impormedical.wmsligero.com/img/slider/1.jpg',
        'https://impormedical.wmsligero.com/img/slider/2.jpg',
        'https://impormedical.wmsligero.com/img/slider/3.jpg'
        /*'/img/slider/4.jpg', 
        'img/slider/5.jpg', 
        'img/slider/6.jpg', 
        'img/slider/7.jpg'*/
      ]
    }),

    computed: {
      ...mapGetters({ loading: 'app/loading' })
    },

    methods: {}
  })
